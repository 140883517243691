import React, { createRef, PureComponent } from "react";
import classNames from "classnames";
import ReactAudioPlayer from "react-audio-player";

import moment from "moment";
import DomPurify from "dompurify";
import micromarkdown from "../../../../markdown";
import { getUserValidName } from "../../../../utils";
import OperatorAvatar from "../icons/OperatorAvatar";
import AssistantAvatar from "../icons/AssistantAvatar";
import RoundedImage from "../RoundedImage";
import UserAvatar from "../icons/UserAvatar";
import ImageThumbnail from "./ImageThumbnail";
import FileMessage from "./FileMessage";
import Buttons from "../Form/Buttons";
import Carousel from "./Carousel";
import Opengraph from "./Opengraph";
import localization from "../../../../localization";
import SystemMessage from "../SystemMessage";
import { Spinner } from "../Spinner";
import VoiceMessage from "./VoiceMessage";
const t = localization.translate;

export const URL_REGEXP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
const IMG_REGEXP = /\.(gif|jpg|jpeg|tiff|png)(\?.*){0,1}$/i;
const AUDIO_REGEXP = /\.(mp3|wav|wave|aiff)(\?.*){0,1}$/i;
const VOICE_REGEXP = /\.(webm)(\?.*){0,1}$/i;
const VIDEO_REGEXP = /\.(mov|mp4|avi|flv|wmv)(\?.*){0,1}$/i;

function sanitize(text) {
  return DomPurify.sanitize(text, { ADD_TAGS: ["cmd"] });
}

class Message extends PureComponent {
  htmlRef = createRef();
  html = "";
  extractscript = "";

  state = {
    openGraphUrls: [],
    isVideoUrlValid: null,
    isImageUrlValid: null,
  };

  componentDidMount() {
    if (this.extractscript) {
      window.eval(this.extractscript[1]);
    }

    if (this.html) {
      const hasUrl = URL_REGEXP.test(this.html);
      if (hasUrl) {
        const urls = this.html.match(URL_REGEXP);
        const openGraphUrls = Array.from(new Set(urls));
        this.setState({ openGraphUrls });
      }
      if (this.htmlRef.current) {
        const nodes = this.htmlRef.current.querySelectorAll("a");
        if (nodes) {
          const arrayNodes = Array.from(nodes);
          arrayNodes.forEach((node) => {
            if (!node.target) {
              node.target = "_blank";
            }
          });
        }
      }
    }

    const { textRequest } = this.props.message;
    if (textRequest?.text && URL_REGEXP.test(textRequest?.text)) {
      const urls = textRequest?.text.match(URL_REGEXP);
      const openGraphUrls = Array.from(new Set(urls));
      this.setState({
        openGraphUrls,
      });
    }
    this.checkImageUrl(this.props.message.text?.imageUrl);
    this.checkVideoUrl(this.props.message.text?.videoUrl);
  }

  formatText = (text) => {
    const hasUrl = URL_REGEXP.test(text);
    let result = text;
    if (hasUrl) {
      const urls = text.match(URL_REGEXP);
      new Set(urls).forEach((url) => {
        result = result
          .split(url)
          .join(
            `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
          );
      });

      return (
        <span
          data-test-id="Justwidget.Message.RequestText"
          dangerouslySetInnerHTML={{ __html: sanitize(result) }}
        />
      );
    }

    return <span data-test-id="Justwidget.Message.RequestText">{result}</span>;
  };

  removeOpenGraphItemByIndex = (index) => () => {
    const urls = [...this.state.openGraphUrls];
    urls.splice(index, 1);
    this.setState({
      openGraphUrls: urls,
    });
  };

  get audioUrl() {
    const {
      message: { text, textRequest },
    } = this.props;

    const textAudio =
      text?.audioUrl || (AUDIO_REGEXP.test(text?.text) && text?.text);

    return (
      (AUDIO_REGEXP.test(textRequest?.audioUrl) && textRequest.audioUrl) ||
      textAudio
    );
  }

  get voiceUrl() {
    const { message } = this.props;

    return message.textRequest?.text &&
      VOICE_REGEXP.test(message.textRequest?.text)
      ? message.textRequest?.text
      : "";
  }

  get imageUrl() {
    const { message } = this.props;
    const textImg = message.text?.imageUrl || message.text?.text;
    const imageUrl =
      (IMG_REGEXP.test(message.textRequest?.text) &&
        message.textRequest.text) ||
      (IMG_REGEXP.test(textImg) && textImg);

    if (imageUrl) {
      this.setState({ isImageUrlValid: true });
      return imageUrl;
    }

    return message.text?.imageUrl;
  }

  checkImageUrl = (url) =>
    new Promise(() => {
      if (!url) return;
      const img = new Image();
      img.src = url;
      img.onload = () => this.setState({ isImageUrlValid: true });
      img.onerror = () => this.setState({ isImageUrlValid: false });
    });

  checkVideoUrl = (url) => {
    if (!url) {
      return;
    }
    const video = document.createElement("video");
    video.setAttribute("src", url);
    video.addEventListener("canplay", () => {
      this.setState({ isVideoUrlValid: true });
      video.remove();
    });
    video.addEventListener("error", (e) => {
      this.setState({ isVideoUrlValid: false });
      video.remove();
    });
  };

  get videoUrl() {
    const { message } = this.props;
    const textVideo = message.text?.videoUrl || message.text?.text;

    const videoUrl =
      (VIDEO_REGEXP.test(message.textRequest?.text) &&
        message.textRequest.text) ||
      (VIDEO_REGEXP.test(textVideo) && textVideo);

    if (videoUrl) {
      this.setState({ isVideoUrlValid: true });
      return videoUrl;
    }

    return message.text?.videoUrl;
  }

  get fileUrl() {
    const { imageUrl, audioUrl, videoUrl, voiceUrl } = this;
    const { message } = this.props;
    const text = message.textRequest?.text || message.text?.text || "";

    const lastDot = text.lastIndexOf(".");
    const isFile =
      lastDot >= 0 && URL_REGEXP.test(text.text)
        ? text.substring(lastDot + 1).length > 0
        : false;

    return (
      !audioUrl &&
      !imageUrl &&
      !videoUrl &&
      !voiceUrl &&
      (text.includes(".selstorage.ru/") || isFile) &&
      text?.startsWith("http") &&
      !text?.includes(" ") &&
      text
    );
  }

  render() {
    const { imageUrl, audioUrl, videoUrl, fileUrl, voiceUrl } = this;
    const {
      options,
      message: { text, textRequest, uploadError },
      propsTimestamp,
      showImageOverlay,
      showErrorOverlay,
      sendText,
      buttonClick,
    } = this.props;

    const { avatar, captions, names, forcedLanguage, linkPreview } = options;
    const {
      operatorInfo,
      file,
      systemMessage: { systemMessageType } = {},
    } = text || {};

    const isUser = Boolean(textRequest || uploadError);
    const isOperator = !isUser && Boolean(text?.operatorInfo);
    const isAsst = !isUser && !isOperator && Boolean(text);
    const isCaption = !!(
      text?.caption?.trim() ||
      (text?.text?.trim() && (videoUrl || imageUrl))
    );
    const operatorName = isOperator
      ? getUserValidName(operatorInfo.firstName, operatorInfo.lastName)
      : null;

    if (!isUser && (text?.text || text.html) && !isOperator) {
      if (
        !text.text.startsWith("{" || "<html" || "<!DOCTYPE " || "<!doctype ") &&
        !text.html
      ) {
        this.html = micromarkdown.parse(text.text);
        this.html = this.html.replace(/<a/g, ` <a`);
        this.extractscript = /<script>(.+)<\/script>/gi.exec(this.html);
        if (this.extractscript) {
          this.html = this.html.replace(this.extractscript[0], "");
        }
        if (typeof this.html === "string") {
          this.html = this.html.trimStart();
        }
      }
      if (text.html) {
        this.html = text.html;
      }
    }

    if (text && text.audioUrl) {
      this.html = "";
    }

    if (text && !isCaption && (videoUrl || imageUrl)) {
      this.html = "";
      text.text = "";
    }

    const inlineButtons =
      !options.buttonsSamePlace &&
      text?.buttons?.filter((button) => typeof button !== "string");

    if (systemMessageType) {
      return <SystemMessage text={text} timestamp={propsTimestamp} />;
    }

    return (
      <>
        <div
          className={classNames({
            "justwidget--message": true,
            "justwidget--message_image": imageUrl && !isCaption,
            "justwidget--message_user": isUser,
            "justwidget--message_operator": isOperator,
            "justwidget--message_asst": isAsst,
            "justwidget--message_cell": avatar.show,
          })}
          data-test-id="Justwidget.Message"
        >
          {avatar.show && isAsst && (
            <RoundedImage
              url={avatar.assistantAvatarUrl}
              image={<AssistantAvatar />}
            />
          )}
          {avatar.show && isOperator && (
            <RoundedImage
              url={operatorInfo.avatarUrl || avatar.operatorAvatarUrl}
              image={<OperatorAvatar />}
            />
          )}

          <div
            className={classNames("justwidget--message_content", {
              "justwidget--message_spinner": this.props.isSpinner,
            })}
            data-test-id="Justwidget.Message.Content"
          >
            {!imageUrl && !fileUrl && names.show && (
              <div className="justwidget--username">
                {isUser && names.userName}
                {isAsst && names.assistantName}
                {isOperator && (operatorName || names.operatorDefaultName)}
              </div>
            )}
            {this.state.isImageUrlValid === false && (
              <>
                <i>{t("Imageurl:NotValid")}</i>:<br />
                <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                  {imageUrl}
                </a>
              </>
            )}

            {imageUrl && this.state.isImageUrlValid && (
              <ImageThumbnail
                showImageOverlay={showImageOverlay}
                url={imageUrl}
              />
            )}

            {this.state.isVideoUrlValid === false && (
              <>
                <i>{t("Videourl:NotValid")}</i>:<br />
                <a href={videoUrl} target="_blank" rel="noopener noreferrer">
                  {videoUrl}
                </a>
              </>
            )}
            {videoUrl && this.state.isVideoUrlValid && (
              <FileMessage url={videoUrl} />
            )}

            {Boolean(this.html && !audioUrl && !fileUrl) && (
              <div
                ref={this.htmlRef}
                className={classNames("justwidget--lineHeightNormal", {
                  "justwidget--withCaption": videoUrl || imageUrl,
                })}
                data-test-id="Justwidget.Message.Text"
                dangerouslySetInnerHTML={{
                  __html: sanitize(this.html),
                }}
              />
            )}

            {!this.html && !text?.audioUrl && text?.text && (
              <div
                className="justwidget--lineHeightNormal"
                data-test-id="Justwidget.Message.Text"
              >
                {this.formatText(text?.text)}
              </div>
            )}

            {fileUrl && <FileMessage url={fileUrl} />}

            {file?.fileUrl && (
              <FileMessage url={file.fileUrl} name={file.name} />
            )}

            {textRequest &&
              !audioUrl &&
              !imageUrl &&
              !fileUrl &&
              !voiceUrl &&
              this.formatText(textRequest.text)}

            {linkPreview.show &&
              this.state.openGraphUrls.map((url, index) => (
                <Opengraph
                  url={url}
                  key={`${url}`}
                  onHide={this.removeOpenGraphItemByIndex(index)}
                />
              ))}

            {text?.errorStacktrace && (
              <span>
                {forcedLanguage
                  ? t("captions.errorOccurred")
                  : captions.errorOccurred}{" "}
                <a
                  onClick={() => showErrorOverlay(text.errorStacktrace)}
                  className="juswidget--stacktrace-link"
                >
                  {forcedLanguage ? t("captions.viewLog") : captions.viewLog}
                </a>
              </span>
            )}

            {audioUrl && (
              <ReactAudioPlayer
                src={audioUrl}
                controlsList="nodownload nofullscreen noremoteplayback"
                controls
              />
            )}

            {voiceUrl && (
              <VoiceMessage url={voiceUrl} name={textRequest.text} />
            )}

            {uploadError && <span style={{ color: "red" }}>{uploadError}</span>}
            {!this.props.isSpinner && (
              <span className="justwidget--message--date">
                {moment(propsTimestamp).format("HH:mm")}
              </span>
            )}
            {this.props.isSpinner && <Spinner />}
          </div>
          {avatar.show && isUser && (
            <RoundedImage url={avatar.userAvatarUrl} image={<UserAvatar />} />
          )}
        </div>
        {text && text.carousel && text.carousel.length > 0 && (
          <Carousel items={text.carousel} sendText={sendText} />
        )}
        {inlineButtons?.length > 0 && (
          <Buttons
            classname="justwidget--buttons-inline"
            buttons={inlineButtons}
            buttonClick={buttonClick}
          />
        )}
      </>
    );
  }
}

Message.propTypes = {};

export default Message;
